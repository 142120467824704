import make from "../make";

export default {
  key: "strategy",
  title: "Strategy / Research / Analysis",
  items: [
    make("Keyphrase Research"),
    make("Monthly Reporting: Keyword, Traffic & Goal"),
    make("Canonicalization Analysis"),
    make("Website Usability Analysis", false),
    make("Searcher Experience Analysis", false),
    make("Dedicated Account Representative", false),
    make("Google Analytics Traffic Analysis", false),
    make("Competitor Intelligence Report", false),
    make("Information Architecture Audit", false),
    make("Predictive Keyword Analysis", false)
  ]
};

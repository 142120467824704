import PATH from '@/paths';

const FEATURE_DATA = {
  'website-development': {
    key: 'WEB',
    id: 'website-design-and-development',
    title: 'Hotel Website Design And Development',
    paragraphs: [
      `Travelers typically visit more than a dozen websites before booking. Does your website deliver a seamless user experience from search to booking? Our development team specializes in balancing design, functionality and experience to maximize your website's effectiveness. Our extensive hospitality experience provides a deep understanding of the complexities of optimizing revenue from all channels. Custom solutions and our service focused team can help you achieve and exceed your goals from your primary digital marketing channel.`,
    ],
    image: {
      src: require('@/assets/digital/bars/n/1.jpg'),
      loading: require('@/assets/digital/bars/l/1.jpg'),
    },
    link: {
      details: true,
      buttonText: 'Website Packages',
      url: '/digital/website-design-and-development',
      title: 'Detailed Information About Website Development',
      label: 'Detailed Information About Website Development',
    },
  },
  'search-engine-optimization': {
    key: 'SEO',
    id: 'seo',
    title: 'Search Engine Optimization',
    paragraphs: [
      `Professional search engine optimization ensures optimal visibility and search engine rank. ATH utilizes industry best practices alongside proprietary techniques to make your website your most profitable distribution channel.`,
      `Our Google and HubSpot certified experts help your website gain visibility and drive organic traffic on highly competitive hospitality-related terms through a focus on search engine optimization fundamentals. Our comprehensive approach utilizes time-tested, proven techniques and cutting-edge strategies customized to the hotel and travel buying model.`,
    ],
    image: {
      src: require('@/assets/digital/bars/n/3.jpg'),
      loading: require('@/assets/digital/bars/l/3.jpg'),
    },
    link: {
      details: true,
      buttonText: 'SEO Details',
      url: '/digital/seo',
      title: 'Detailed Information About Search Engine Optimization',
      label: 'Detailed Information About Search Engine Optimization',
    },
  },
  'social-media': {
    key: 'SM',
    id: 'social-media-marketing',
    title: 'Social Media Marketing',
    paragraphs: [
      `ATH's Social Media Management service includes dedicated attention from an experience Social Media Marketing Manager. Professional graphics, content development, publishing, paid campaign management, and comprehensive reporting ensure a return on your hotel's social media investment.`,
      `Six out of ten travelers change plans based on what they read on social media. Who is controlling your messaging?`,
    ],
    image: {
      src: require('@/assets/digital/bars/n/5.jpg'),
      loading: require('@/assets/digital/bars/l/5.jpg'),
    },
    link: {
      // // details: true,
      // details: false,
      // external: true,
      // buttonText: 'Social Media Options',
      // // url: "/digital/social-media-marketing",
      // url: 'https://allthingshospitality.com/pdf/ATH-social-media-2020.pdf',
      // title: 'Social Media Package Info',
      // label: 'Social Media Package Info',
    },
  },
  'email-marketing': {
    key: 'EM',
    id: 'email-marketing',
    title: 'Email Marketing',
    paragraphs: [
      `Email marketing is still one of the most efficient ways to deliver the right offer to the right person at the right time. ATH email marketing campaigns deliver compelling visual and text content in a mobile friendly format so users can take action anywhere, anytime. We safeguard your domain and ensure high delivery rates by following industry best practices and meeting CAN SPAM act requirements.`,
    ],
    image: {
      src: require('@/assets/digital/bars/n/4.jpg'),
      loading: require('@/assets/digital/bars/l/4.jpg'),
    },
    link: {
      details: true,
      buttonText: 'Email Marketing Facts',
      url: '/digital/email-marketing',
      title: 'Email Marketing Detailed Information',
      label: 'Email Marketing Detailed Information',
    },
  },
  'digital-presentations': {
    key: 'DP',
    id: 'digital-presentation',
    title: 'Digital Presentations',
    paragraphs: [
      `Give your team the selling tools they need with our unique interactive digital marketing solutions for hotels. These digital selling tools allow you to expand your marketing efforts and reach more potential guests through email, social media, vanity websites and more. Give your team the selling tools they need with our unique interactive digital marketing solutions for hotels. These digital selling tools allow you to expand your marketing efforts and reach more potential guests through email, social media, vanity websites and more.`,
    ],
    image: {
      src: require('@/assets/digital/bars/n/2.jpg'),
      loading: require('@/assets/digital/bars/l/2.jpg'),
    },
    link: {
      pdf: false,
      url: PATH.INTERACTIVE.DEFAULT,
      title: 'Digital Presentations',
      label: 'Digital Presentations Link - Internal Link',
    },
  },
};

export const links = Object.keys(FEATURE_DATA);
export const features = Object.values(FEATURE_DATA);

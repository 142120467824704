<template>
  <div class="detailed-information-container">
    <div class="headline-container">
      <h3 class="headline standout">Social Media Management Services</h3>
    </div>
    <div class="content-container">
      <div class="content content-left">
        <div class="line-item primary-headline first">
          <p class="normal" v-if="overVSmall">Service</p>
          <p class="normal" v-if="overVSmall">Core</p>
          <p class="normal" v-if="overVSmall">Premium</p>
        </div>
        <div class="line-item" v-for="(item, index) in lineItems.services" :key="index" :class="{'dark' : index % 2 !== 0}">
          <div class="description">
            <p class="standout">{{ item.headline }}</p>
            <p class="normal">{{ item.text }}</p>
          </div>
          <coreCheck v-if="index % 2 === 0 && overVSmall"/>
          <premCheck v-else-if="overVSmall" />
          <coreCheck v-if="index % 2 === 0 && overVSmall"/>
          <premCheck v-else-if="overVSmall" />
        </div>
      </div>
      <div class="content content-right" v-if="overVSmall">
        <div class="line-item primary-headline second">
          <p class="normal">Publishing Schedule</p>
          <p class="normal">Core</p>
          <p class="normal">Premium</p>
        </div>
        <div class="line-item" v-for="(item, index) in lineItems.schedule" :key="index" :class="{'dark' : index % 2 !== 0}">
          <p class="standout media-outlet">{{ item.outlet }}</p>
          <p class="normal">{{ item.core }}</p>
          <p class="normal">{{ item.premium }}</p>
        </div>
      </div>
      <CTA v-on:contact="contact">Contact Us For Pricing</CTA>
    </div>
  </div>
</template>

<script>
import coreCheck from "@/assets/digital/check_blue.svg";
import premCheck from "@/assets/digital/check_oran.svg";
import CTA from "@/components/digital/below/cta-button/CTA.vue";
import lineItems from "@/lib/digital/social-media/";
import ScreenSize from "@/mixins/screen-size.js";

export default {
  components: {
    coreCheck,
    premCheck,
    CTA
  },
  mixins: [ScreenSize],
  data() {
    return { lineItems };
  },
  methods: {
    contact() {
      this.$emit("contact");
    }
  },
  metaInfo: {
    title: "Social Media Marketing | Hotel Social Media Managment",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "ATH's Social Media Management service includes dedicated attention from an experience Social Media Marketing Manager. Professional graphics, content development, publishing, paid campaign management, and comprehensive reporting ensure a return on your hotel's social media investment."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/digital/social-media-marketing"
      }
    ]
  }
};
</script>

<style scoped>
.detailed-information-container {
  width: 80%;
  margin: 0 auto;
}

.headline-container {
  width: 100%;
  text-align: center;
}

.headline {
  font-size: calc(4rem + 1vw);
  margin: 0 auto 0.5rem auto;
}

.content {
  margin-bottom: 0.5rem;
}

.primary-headline {
  color: white;
  font-size: calc(1rem + 0.25vw);
}

.primary-headline.first {
  background: var(--ath-blue);
}

.primary-headline.second {
  background: var(--ath-orange);
}

.primary-headline p:first-child,
.media-outlet {
  padding-left: 2rem;
}

.line-item {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  place-items: center center;

  border-left: 3px solid var(--whole-background);
  border-right: 3px solid var(--whole-background);
}

.line-item:first-of-type {
  border: none;
  border-bottom: 3px solid var(--whole-background);
}

.line-item:last-of-type {
  border-bottom: 3px solid var(--whole-background);
}

.line-item p:first-child,
.line-item div:first-child {
  justify-self: start;
  align-self: start;
}

.dark {
  background: var(--whole-background);
}

.description {
  padding: 0.5rem 2rem;
  font-size: calc(1rem + 0.25vw);
}

.description p:first-child {
  color: var(--ath-orange);
  margin-bottom: 0;
}

.description p:last-child {
  color: var(--ath-blue);
  margin-top: 0;
}

@media screen and (max-width: 1025px) {
  .detailed-information-container {
    width: 90%;
  }

  .detailed-information-container p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 500px) {
  .headline {
    font-size: calc(2rem + 1vw);
    margin: 0;
  }

  .description {
    grid-column: span 3;
    padding: 0.5rem;
    text-align: center;
  }
  .primary-headline {
    min-height: 0.5rem;
  }
  .line-item p,
  .line-item p:first-child {
    justify-self: center;
    align-self: center;
  }
}
</style>

<template>
  <div class="detailed-information-container">
    <div class="headline-container">
      <h3 class="headline standout">Web Design & Development Services</h3>
    </div>
    <div class="content-container">
      <div class="content content-one">
        <div class="content-headline-container">
          <h4 class="content-headline standout">Quick Deploy Website</h4>
          <p class="byline normal">Out of the box mobile-friendly responsive designs with customization options for your property and brand.</p>
        </div>
        <div class="content-list-container">
          <ul class="list content-list content-list-one">
            <li class="normal content-list-item" v-for="(item, index) in list.quick" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
      <div class="content content-two">
        <div class="content-headline-container">
          <h4 class="content-headline standout">Custom Design Website</h4>
          <p class="byline normal">Custom website to meet your property's specific needs.</p>
        </div>
        <div class="content-list-container content-list-container-two">
          <ul class="list content-list content-list-two" v-for="(list, index) in list.custom" :key="index" :class="{ 'left-align': index === 0, 'center-align': index === 1, 'right-align': index === 2 }">
            <li class="normal content-list-item" v-for="(item,index) in list" :key="index">{{ item }}</li>
          </ul>
        </div>        
      </div>
    </div>
    <div class="boxes-container">
      <div class="boxes-headline-container">
        <h4 class="boxes-headline standout">Website Hosting</h4>
        <div class="byline normal">All Things Hospitality offers full-service hosting regardless of your website's origin.</div>
      </div>
      <div class="box-container">
        <div class="box box-one">
          <div class="box-headline-container">
            <h4 class="box-headline standout">Standard Hosting</h4>
          </div>
          <div class="box-list-container box-list-container-one">
            <ul class="list box-list" v-for="(list, index) in list.standard" :key="index" :class="{ 'left-align': index === 0, 'right-align': index === 1 }">
              <li class="box-list-item normal" v-for="(item, index) in list" :key="index">{{ item }}</li>
            </ul>
          </div>
        </div>
        <div class="box box-two">
          <div class="box-headline-container">
            <h4 class="box-headline standout">Premium Hosting</h4>
          </div>
          <div class="box-list-container">
            <ul class="list box-list box-list-two">
              <li class="box-list-item normal" v-for="(item, index) in list.premium" :key="index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <CTA v-on:contact="contact">Contact Us For Pricing</CTA>
  </div>
</template>

<script>
import CTA from "@/components/digital/below/cta-button/CTA.vue";

export default {
  components: { CTA },
  data() {
    return {
      list: {
        quick: [
          "Up to 10 page website",
          "Easy to use CMS",
          "Monthly payment options available",
          "Google Analytics & Social Integration"
        ],
        custom: [
          [
            "Custom design, up to 20 pages",
            "Mobile-friendly responsive design",
            "Consultation with design team"
          ],
          [
            "Booking engine integration",
            "Social integration",
            "CMS integration available"
          ],
          [
            "Professional graphics assistance",
            "Google Analytics and Webmaster setup",
            "Monthly payment options available"
          ]
        ],
        standard: [
          [
            "Single domain hosted on secure Linux servers",
            "Routine back up of website files",
            "Website statistics tracking via AW Stats"
          ],
          [
            "Google Analytics and Webmaster integration (set up fee applies)",
            "Up to 4 web forms with unlimited storage & unlimited transfer",
            "Quarterly or annual billing"
          ]
        ],
        premium: [
          "All standard services",
          "Two additional hosted forms",
          "CPanel or Plesk access"
        ]
      }
    };
  },
  metaInfo: {
    title: "Custom Website Design | Hotel Web Development",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "We build custom websites that deliver usablity, accessibility compliance, and performance with beautiful design choices."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href:
          "https://allthingshospitality.com/digital/website-design-and-development"
      }
    ]
  },
  methods: {
    contact() {
      this.$emit("contact");
    }
  }
};
</script>

<style scoped>
.detailed-information-container {
  width: 80%;
  margin: 0 auto;
}

.headline-container {
  width: 100%;
  text-align: center;
}

.headline {
  font-size: calc(4rem + 1vw);
  margin: 0;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

.content-headline-container,
.content-list-container {
  padding: 1rem 2rem;
}

.content-headline-container,
.boxes-headline-container {
  text-align: center;
  padding: 0.5rem 0;
  padding: 1rem 2rem;
}

.content-headline-container {
  color: white;
  background: var(--ath-blue);
}

.boxes-headline-container {
  color: white;
  background: var(--ath-orange);
}

.content-headline,
.boxes-headline {
  margin: 0;
  font-size: calc(1rem + 0.5vw);
}

.content-headline-container .byline,
.boxes-headline-container .byline {
  margin: 0.5rem 0 0 0;
  font-size: calc(0.75rem + 0.25vw);
}

.content-list-container {
  border-left: 3px solid var(--whole-background);
  border-right: 3px solid var(--whole-background);
}

.content-list-container-two {
  border-bottom: 3px solid var(--whole-background);
}

.content-list-one {
  display: flex;
  justify-content: space-between;
}

.content-two .content-list-container {
  display: flex;
  justify-content: space-between;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.content-list-item,
.box-list-item {
  font-size: calc(1rem + 0.25vw);
}

.content-list-two .content-list-item:nth-of-type(2),
.box-list-container-one .box-list-item:nth-of-type(2) {
  margin: 1rem 0;
}

.boxes-container {
  margin-bottom: 0.5rem;
}

.box {
  border: 3px solid var(--ath-blue);
}

.box-headline-container {
  text-align: center;
  background: var(--ath-blue);
  color: white;
  padding: 0.5rem 0;
}

.box-headline {
  margin: 0;
}

.box-list {
  padding: 0 2rem;
}

.box-list-container-one,
.box-list-two {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1025px) {
  .detailed-information-container {
    width: 90%;
  }
}

@media screen and (max-width: 813px) {
  .content-list-container {
    padding: 1rem 0.5rem;
  }
  .content-list-one {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .headline {
    font-size: calc(2rem + 1vw);
    margin: 0;
  }
  .content-headline-container,
  .content-list-container,
  .boxes-headline-container {
    padding: 0.5rem;
  }

  .content-list-one,
  .content-list-container-two,
  .box-list-container,
  .box-list-two {
    flex-direction: column;
  }

  .content-list-two,
  .box-list {
    text-align: center;
  }

  li {
    margin: 0.25rem;
  }

  li:first-of-type,
  .content-list-two:last-of-type,
  .box-list:nth-of-type(2) {
    margin-top: 0;
  }

  li:last-of-type,
  .content-list-two:first-of-type {
    margin-bottom: 0;
  }
}
</style>

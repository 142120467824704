const make = (headline, text) => ({ headline, text });

export default [
  make("Social Media Manager", "Dedicated social media specialist"),
  make(
    "Professional Graphics",
    "Up to 2 hours of professional graphic design services."
  ),
  make(
    "ATH Social Marketing Platform Access",
    "Access to platform for additional posts or comments."
  ),
  make(
    "Social Content Publishing",
    "Optimization, and Development Assistance."
  ),
  make(
    "Basic Monthly Reporting",
    "Latest activity from all selected social channels."
  ),
  make(
    "Advertising Campaign Management",
    "Dedicated expert to develop and manage paid social campaigns for optimal performance. Four quarterly campaigns."
  )
];

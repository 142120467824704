const make = (outlet, core = 2, premium = 4) => ({
  outlet,
  core: `${core}x weekly`,
  premium: `${premium}x weekly`
});

export default [
  make("Facebook"),
  make("Twitter"),
  make("Instagram"),
  make("LinkedIn"),
  make("Pinterest")
];

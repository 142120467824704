<template>
  <div class="container">
    <InfoBar v-for="(feature, index) in features" 
      :key="feature.key" 
      :feature="feature" 
      :number="index + 1"
      v-on:contact="contact($event)"
    />
  </div>  
</template>

<script>
import InfoBar from "@/components/digital/below/information/bar/InformationBar.vue";
import { features, links } from "./features.js";

export default {
  components: { InfoBar },
  data() {
    return {
      features,
      links
    };
  },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  overflow: hidden;
}

.bar__wrapper {
  width: 90%;
  margin: 3rem 0;
}

.bar__wrapper:nth-of-type(even) {
  margin-left: 10%;
}
</style>

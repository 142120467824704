<template>
  <div class="detailed-information-container">
    <div class="headline-container">
      <h3 class="headline standout uppercase">Email Marketing</h3>
    </div>
    <div class="content-container">
      <div class="content content-left">
        <div class="content-title">
          <h4 class="title standout uppercase">Professional email marketing campaign development</h4>
        </div>
        <div class="content-text">
          <p class="normal">We provide a custom campaign design to mach your website, print marketing or other campaign. We will ensure that your email marketing campaign meets your specific brand requirements and make your sales message stand out.</p>
        </div>
      </div>
      <div class="content content-right">
        <div class="content-title">
          <h4 class="title standout uppercase">Package Includes</h4>
        </div>
        <div class="content-lists">
          <ul class="list list-left">
            <li class="normal">Professional Graphics</li>
            <li class="normal">Social Media Integration</li>
            <li class="normal">HTML programming</li>
            <li class="normal">Send to your list of up to 5,000 recipients</li>
          </ul>
          <ul class="list list-right">
            <li class="normal">CAN-SPAM Compliance</li>
            <li class="normal">Enhanced Reporting</li>
            <li class="normal">Contact List Management</li>
          </ul>
        </div>
      </div>
    </div>
    <CTA v-on:contact="contact">Contact Us For Pricing</CTA>
  </div>
</template>

<script>
import CTA from "@/components/digital/below/cta-button/CTA.vue";

export default {
  components: { CTA },
  methods: {
    contact() {
      this.$emit("contact");
    }
  },
  metaInfo: {
    title: "Email Marketing | Hotel Email Campaign",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Email marketing is still one of the most efficient ways to deliver the right offer to the right person at the right time. ATH email marketing campaigns deliver compelling visual and text content in a mobile friendly format so users can take action anywhere, anytime"
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/digital/email-marketing"
      }
    ]
  }
};
</script>

<style scoped>
.detailed-information-container {
  width: 80%;
  margin: 0 auto;
}

.headline-container {
  text-align: center;
}

.headline {
  font-size: 5rem;
  margin: 0;
}

.content-container {
  border: 3px solid var(--whole-background);
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.content {
  flex-basis: 50%;
}

.content-title {
  text-align: center;
  font-size: 1.75rem;
}

.title {
  margin: 0;
}

.content-text {
  font-size: calc(1rem + 0.25vw);
}

.content-lists {
  display: flex;
  flex-wrap: wrap;
}

.list {
  list-style: none;
}

.list > li::before {
  content: "\2022";
  font-size: 1.25em;
  padding-right: 1.1225em;
}

.list-left > li::before {
  color: var(--ath-blue);
}

.list-right > li::before {
  color: var(--ath-orange);
}

@media screen and (max-width: 1025px) {
  .detailed-information-container {
    width: 90%;
  }

  .detailed-information-container p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 813px) {
  .content-container {
    padding: 1.25rem;
  }

  .content {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 500px) {
  .headline {
    font-size: calc(2rem + 1vw);
    margin: 0;
  }
}
</style>

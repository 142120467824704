import make from "../make";

export default {
  key: "on-page",
  title: "On-page Optimization Tactics",
  items: [
    make("Meta tags, Image Alt Text, Header Tags"),
    make("Internal Link Restructuring"),
    make("Schema HTML Tags"),
    make('Rel="publisher"'),
    make("Duplicate Content Analysis"),
    make("Search Optimized Copywriting", false),
    make(`Earned Media "Citation" Building`, false),
    make("Website Design Analyis", false)
  ]
};

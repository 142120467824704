import make from "../make";

export default {
  key: "external",
  title: "External Optimization Tactics",
  items: [
    make("Optimize robots.txt and GoogleBot Crawls"),
    make("Creation & registration of sitemap.xml"),
    make("Google My Business Profile Optimization"),
    make("Google Analytics Set Up"),
    make("Google Analytics Conversion Tracking"),
    make("Link Audit - Redirect / Reclamation"),
    make("Google Data Highlights"),
    make("Creation of video & image xml sitemaps", false),
    make("Web server analysis", false)
  ]
};

<template>
  <div class="detailed-information-container">
    <div class="headline-container">
      <h3 class="headline standout">SEO Services</h3>
    </div>
    <div class="content-box-container">      
      <div class="content-box" v-for="list in lists" :key="list.key">
        <div class="content-box-title-container">
          <h4 class="content-box-title standout">{{ list.title }}</h4>
          <p class="standout" v-if="overVSmall">CORE</p>
          <p class="standout" v-if="overVSmall">PREMIUM</p>
        </div>
        <div class="content-box-list-container">
          <ul class="content-box-list">
            <li class="content-box-list-item" v-for="(item, index) in list.items" :key="index">
              <div class="left">
                <p class="normal">{{ item.title }}</p>
              </div>
              <div class="middle" v-if="overVSmall">
                <coreCheck v-if="item.core" class="mark"/>
              </div>
              <div class="right" v-if="overVSmall">
                <premCheck class="mark"/>
              </div>              
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="blurbs-container">
      <div class="blurb">
        <div class="blurb-title">
          <h4 class="standout">SEO Package Information</h4>
        </div>
        <div class="blurb-content">
          <p class="normal">A solid SEO program includes short-term, mid-term and long-term strategies to gain visibility on highly competitive hospitality-related terms. Because of this, our SEO packages require a 12-month commitment.</p>
        </div>        
      </div>
      <div class="blurb">
        <div class="blurb-title">
          <h4 class="standout">Paid Search marketing</h4>
        </div>
        <div class="blurb-content">
          <p class="normal">ATH will utilize our access to 200+ trusted travel brands and 500M+ unique travelers to display your targeted PPC campaign. We offer month to month service. Recommended campaign length is 3-month for optimal ROI.</p>
        </div>
      </div>
    </div>
    <CTA v-on:contact="contact">Contact Us For Pricing</CTA>
  </div>
</template>

<script>
import lists from "@/lib/digital/seo";
import coreCheck from "@/assets/digital/check_blue.svg";
import premCheck from "@/assets/digital/check_oran.svg";
import CTA from "@/components/digital/below/cta-button/CTA.vue";
import ScreenSize from "@/mixins/screen-size.js";

export default {
  components: {
    coreCheck,
    premCheck,
    CTA
  },
  mixins: [ScreenSize],
  data() {
    return { lists };
  },
  methods: {
    contact() {
      this.$emit("contact");
    }
  },
  metaInfo: {
    title: "Search Engine Optimization | Hotel Website SEO",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Professional search engine optimization ensures optimal visibility and search engine rank. ATH utilizes industry best practices alongside proprietary techniques to make your website your most profitable distribution channel."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/digital/seo"
      }
    ]
  }
};
</script>

<style scoped>
.detailed-information-container {
  width: 80%;
  margin: 0 auto;
}

.headline-container {
  width: 100%;
  text-align: center;
}

.headline {
  font-size: calc(4rem + 1vw);
  margin: 0;
}

.content-box-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 0.5rem 0;
}

.content-box {
  width: calc(33.33% - 0.5rem);
  padding: 1rem;
  border: 3px solid var(--whole-background);
}

.content-box-title-container {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}

.content-box-title-container h4,
.content-box-title-container p {
  margin: 0 0 0.5rem 0;
}

.content-box-title-container h4 {
  text-align: left;
}

.content-box-title-container h4,
.left {
  width: 70%;
}

.content-box-title-container p {
  text-align: center;
}

.content-box-title-container p,
.middle,
.right {
  width: 15%;
}

.content-box-list-container {
  border-top: 3px solid var(--whole-background);
}

.content-box-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.content-box-list-item {
  display: flex;
  border-bottom: 3px solid var(--whole-background);
}

.content-box-list-item > p {
  font-size: 1rem;
}

.content-box-list-item:last-of-type {
  border-bottom: none;
}

.left,
.middle,
.right {
  display: flex;
  align-items: center;
}

.middle,
.right {
  justify-content: center;
}

.blurbs-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.blurb {
  border: 3px solid var(--whole-background);
  width: calc(50% - 0.25rem);
  padding: 2rem;
}

.blurb-title {
  text-align: center;
  font-size: 2rem;
}

.blurb-title > h4 {
  margin: 0.25rem;
}

.blurb-content {
  font-size: 1.25rem;
}

@media screen and (max-width: 1375px) {
  .content-box-container {
    flex-direction: column;
  }

  .content-box {
    width: 100%;
    margin: 0.5rem 0 0.25rem 0;
  }
}

@media screen and (max-width: 1025px) {
  .detailed-information-container {
    width: 90%;
  }

  .detailed-information-container p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 769px) {
  .blurbs-container {
    flex-wrap: wrap;
  }
  .blurb {
    width: 100%;
    padding: 0.5rem;
  }

  .blurb:first-of-type {
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 500px) {
  .headline {
    font-size: calc(2rem + 1vw);
    margin: 0;
  }
  .left,
  .content-box-title-container h4 {
    width: 100%;
  }

  .blurb-content {
    text-align: center;
  }
}
</style>

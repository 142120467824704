<template>
<section>
  <div :id="feature.id" ref="feature" class="feature__bar" :class="{left: !even, right: even}">
    <div class="image__wrapper">
      <img v-lazy="feature.image" alt="">
    </div>
    <div class="content__wrapper">
      <h3 class="title standout">
        {{ feature.title }}
      </h3>
      <p class="paragraph normal" v-for="(paragraph, index) in feature.paragraphs" :key="index">
        {{ paragraph }}
      </p>
      <div class="button-container">
        <router-link v-if="feature.link.details" class="feature__button normal" :to="feature.link.url" :title="feature.link.title" :aria-label="feature.link.label">
          {{ feature.link.buttonText }}
        </router-link>
        <a v-else-if="feature.link.external" class="feature__button normal" :href="feature.link.url" :title="feature.link.title" target="_blank" rel="noopener norefferer">
          {{ feature.link.buttonText ? feature.link.buttonText : 'MORE INFORMATION' }}
        </a>
        <router-link v-else class="feature__button normal" :href="feature.link.url" :title="feature.link.title"  :to="feature.link.url">
          {{ feature.link.buttonText ? feature.link.buttonText : 'MORE INFORMATION' }}
        </router-link>
        <button class="feature__button normal" @click="contact">
          CONTACT US
        </button>
      </div>
    </div>
  </div>  
  <transition name="expand">
    <div class="subroute-activated" v-if="subrouteActivated">
      <router-view v-on:contact="contact"/>
    </div>
  </transition>
</section>
</template>

<script>
import SmallScreen from "@/mixins/screen-size";

export default {
  mixins: [SmallScreen],
  props: {
    feature: {
      type: Object,
      required: true
    },
    number: Number
  },
  computed: {
    even() {
      return this.number % 2 === 0;
    },
    subrouteActivated() {
      return this.$route.name === this.feature.id;
    }
  },
  methods: {
    contact() {
      this.$emit("contact", this.feature.id);
    }
  }
};
</script>

<style scoped>
section:nth-of-type(even) > .feature__bar {
  margin-left: 10%;
}

.feature__bar {
  width: 90%;
  margin: 3rem 0;

  display: flex;
  flex-wrap: wrap;
}

.left {
  background: #314657;
  color: #fffefe;
}

.right {
  flex-direction: row-reverse;

  background: #e3e3e3;
  color: #314657;
}

.image__wrapper {
  flex-basis: 30%;
}

.image__wrapper > img {
  width: 100%;
  height: 100%;
  display: block;
}

.content__wrapper {
  padding: 3.5rem;

  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5vw;
}

.paragraph {
  font-size: 1.25vw;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.feature__button {
  border: 1px solid #707070;
  padding: 1rem 5rem;

  box-shadow: 0px 3px 6px black;
  background: #ffffff;

  font-size: 1.25rem;
  color: #ee6630;
  text-decoration: none;
}

.feature__button:hover {
  cursor: pointer;
}

.feature__button:focus,
.feature__button:active {
  background: var(--whole-background);
}

.expand-leave-active {
  transition: all 1s ease-in-out;
}

.expand-enter-active {
  transition: all 1.5s ease-in-out;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  max-height: 0;
}

.expand-enter-to,
.expand-leave {
  opacity: 1;
  max-height: 1000vh;
}

@media screen and (max-width: 1365px) {
  section {
    margin: 1.5rem 0;
  }

  section:nth-of-type(even) > .feature__bar {
    margin-left: 0;
  }

  section:first-of-type {
    margin-top: 0;
  }

  section:last-of-type {
    margin-bottom: 0;
  }

  .feature__bar {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 60.3125%;

    overflow: hidden;
    margin: 0;
  }

  .image__wrapper {
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .content__wrapper {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    color: white;
    background: rgba(0, 0, 0, 0.75);
  }

  .title {
    font-size: 7vw;
  }

  .paragraph {
    font-size: 2.5vw;
  }

  .feature__button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1023px) {
  .paragraph {
    font-size: 2vw;
  }
}

@media screen and (max-width: 768px) {
  .feature__bar {
    padding-bottom: 120.625%;
  }

  .paragraph {
    font-size: 4vw;
  }

  .image__wrapper {
    height: 100%;
  }

  .feature__button {
    padding: 1rem;
    font-size: 3vw;
  }
}
</style>

<template>
  <div class="cta-container">
    <button class="cta standout uppercase" @click="contact"><slot></slot></button>
  </div>
</template>

<script>
export default {
  methods: {
    contact() {
      this.$emit("contact");
    }
  }
};
</script>

<style scoped>
.cta-container {
  text-align: center;
  padding-bottom: 0.5rem;
}

.cta {
  padding: 0.75rem;
  font-size: 1.5rem;
  border: none;
  border-top: 3px solid var(--whole-background);
  border-bottom: 3px solid var(--whole-background);
  background: none;
  transition: background 0.25s;
}

.cta:hover {
  background: var(--whole-background);
}
</style>
